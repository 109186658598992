<template>
  <b-overlay
    :show="$apollo.loading"
    spinner-variant="primary"
  >
    <section class="grid-view">
      <b-card
        v-for="(program, i) in programs"
        :key="i"
        :title="program.title"
        class="ecommerce-card"
        img-alt="card img"
        img-top
        no-body
      >
        <b-card-body>
          <b-card-text>
            <b-card-title class="mb-1">
              {{ program.title }}
              <b-badge
                variant="primary"
                size="lg"
                class="mr-25"
              >
                Round {{ program.round_number }}
              </b-badge>
              <hr>
            </b-card-title>
            <span class="h5 d-block">Application Status: <b-badge
              pill
              :variant="program.status === 'enabled' ? 'success' : ''"
              class="text-capitalize"
              size="lg"
              v-text="program.status"
            /></span>
            <span class="h5 d-block">
              Review Status: <b-badge
                pill
                :variant="program.review_status === 'enabled' ? 'success' : ''"
                class="text-capitalize"
                size="lg"
                v-text="program.review_status"
              /></span>
          </b-card-text>
        </b-card-body>
        <!-- Product Actions -->
        <div class="item-options text-center">
          <b-link
            :to="`/mentor-portal/events/rounds/details/${$route.params.id}/${$route.params.pid}/${program.id}`"
            class="btn btn-primary btn-cart"
          >
            <span>Open</span>
          </b-link>
        </div>
      </b-card>
    </section>
  </b-overlay>
</template>

<script>
import {
  BCard, BCardText, BCardBody, BCardTitle, BOverlay, BLink, BBadge,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BBadge,
    // BCardSubTitle,
    BOverlay,
    BLink,
  },
  data() {
    return {
      programs: [],
      userOrgs: getUserData().id,
    }
  },
  apollo: {
    programs: {
      query() {
        return gql`
          {
            programs_applicationformstable(where: {program_id: {_eq: ${this.$route.params.id}}, review_status: {_neq: "draft"}}, order_by: {round_number: desc}) {
              title
              round_number
              review_status
              status
              id
              }
        }
             `
      },
      update: data => data.programs_applicationformstable,
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
